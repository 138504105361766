import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";

interface HeroCtaProps {
  center?: boolean;
  className?: string;
}

export const HeroCta: React.FunctionComponent<HeroCtaProps> = ({
  center = false,
  className,
}) => {
  const { t } = useTranslation();

  const { contactPhone } = useStaticQuery(graphql`
    query HeroCtaQuery {
      contactMail: contactsJson(jsonId: { eq: "info" }) {
        name
        url
      }
      contactPhone: contactsJson(jsonId: { eq: "phone" }) {
        name
        url
      }
    }
  `);

  return (
    <div
      className={`${className} mt-5 sm:mt-8 flex items-center justify-center ${
        !center && "md:justify-start"
      }`}
    >
      <div className="lg:hidden rounded-md shadow w-full md:w-auto text-center">
        <a
          href={contactPhone.url}
          className={`w-full md:w-auto m-auto flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-3 md:text-lg md:px-10`}
        >
          {t("cta.sales")}
        </a>
      </div>
      <div className="md:mt-0 ml-4 lg:ml-0 w-full md:w-auto text-center">
        <a
          href="#contact-form"
          className={`w-full md:w-auto m-auto flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-3 md:text-lg md:px-10`}
        >
          {t("cta.mail")}
        </a>
      </div>
    </div>
  );
};
